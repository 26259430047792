<script setup>
import { computed } from "vue";
import { useStore } from "@/utils/useStore";
import { GrTag } from "@/components/GrComponents";
import { useRoute } from "vue-router/composables";
import i18n from "@/i18n.js";
import { useContractQueryUpdater } from "@/composables/useContractQueryUpdater";
import { translationContractStatus } from "@/utils/translationStatus";

const store = useStore();
const route = useRoute();

const queryParmsContract = computed(() => {
  return store.getters["getQueryParmsContract"];
});
const { updateQuery } = useContractQueryUpdater(queryParmsContract);

const filtersApplied = computed(() => {
  return store.getters["getFiltersAppliedContracts"];
});

async function clearFilter(key, value = null) {
  if (value) {
    await store.dispatch("updateArrayKeyFieldsContracts", { key, value });
  } else {
    await store.dispatch("updateKeyFieldsContracts", key);
  }

  await store.dispatch("fetchContracts").finally(() => {
    updateQuery();
  });
}
</script>

<template>
  <div data-anima="top" v-if="filtersApplied.length">
    <div class="custom-container-filter">
      <div v-for="(filter, index) in filtersApplied" :key="index">
        <div
          v-if="filter.value"
          class="filter-item"
          :class="{ 'array-container': Array.isArray(filter.value) }"
        >
          <!-- Filters Array -->
          <template v-if="Array.isArray(filter.value)">
            <div
              v-for="(item, itemIndex) in filter.value"
              :key="itemIndex"
              class="filter-item"
            >
              <GrTag
                custom-background="#E7FFF6"
                theme="default"
              >
                <span class="filter-label">{{ i18n.t(filter.label) }}</span>
                <span class="filter-value">
                  {{ translationContractStatus(item).text }}
                </span>
              </GrTag>
              <svg
                @click="clearFilter(filter.key, item)"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="17" height="17" rx="8.5" fill="#00BD7E" />
                <path
                  d="M11.5152 5.48438L5.48486 11.5147M5.48486 5.48438L11.5152 11.5147"
                  stroke="white"
                  stroke-width="1.50759"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </template>

          <!-- Filters  -->
          <template v-else>
            <GrTag
              custom-background="#E7FFF6"
              theme="default"
            >
              <span class="filter-label">{{ i18n.t(filter.label) }}</span>
              <span class="filter-value">{{ filter.value }}</span>
            </GrTag>
            <svg
              @click="clearFilter(filter.key)"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="17" height="17" rx="8.5" fill="#00BD7E" />
              <path
                d="M11.5152 5.48438L5.48486 11.5147M5.48486 5.48438L11.5152 11.5147"
                stroke="white"
                stroke-width="1.50759"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.custom-container-filter {
  display: flex;
  width: var(--w-full);
  gap: var(--spacing-2);
  flex-wrap: wrap;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: rgba(229, 231, 231, 0.3);
  padding: var(--spacing-3);

  .array-container {
    display: flex;
    gap: var(--spacing-2);
    flex-wrap: wrap;
  }

  .filter-item {
    position: relative;
    width: fit-content;

    svg {
      position: absolute;
      top: -4px;
      right: -4px;
      cursor: pointer;
    }

    .filter-label {
      color: var(--dark-500);
      font-size: var(--font-xs);
      font-weight: var(--weight-medium);
    }
    .filter-value {
      color: var(--dark-500);
      font-size: var(--font-xs);
      font-weight: var(--weight-bold);
      margin-left: var(--spacing-1);
    }
  }
}
</style>
