<script setup>
import { onMounted } from "vue";
import { useStore } from "@/utils/useStore";
import { useRouter } from "vue-router/composables";

const store = useStore();
const router = useRouter();

import contractsListHeader from "@/components/Contracts/List/contractsListHeader.vue";
import contractsListFiltersApplied from "@/components/Contracts/List/contractsListFiltersApplied.vue";
import contractsListMetrics from "@/components/Contracts/List/contractsListMetrics.vue";
import contractsList from "@/components/Contracts/List/contractsList.vue";
import contractsListPagination from "@/components/Contracts/List/contractsListPagination.vue";

onMounted(async() => {
  await store.dispatch("fetchFlags");
  const flags = store.getters["getFlags"];
  if (!flags.includes("contract_recurrence")){
    router.push({ name: "Dashboard" });
  }
});
</script>

<template>
  <div class="custom-container-contracts" data-anima="top">
    <contractsListHeader />
    <contractsListFiltersApplied />
    <contractsListMetrics />
    <contractsList />
    <contractsListPagination />
  </div>
</template>

<style scoped lang="scss">
.custom-container-contracts {
  padding: var(--spacing-10) 0px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
</style>
