<script setup>
import { ref, inject } from "vue";
import { computed, onBeforeMount } from "vue";
import { GrTab, GrTabHeaderItem, GrTag } from "@/components/GrComponents";
import VueApexCharts from "vue-apexcharts";
import { useStore } from "@/utils/useStore";
import { translationContractStatus } from "@/utils/translationStatus";
import { useMoney } from "@/composables/useMoney";

const changeShowGraphic = inject("changeShowGraphic");
const store = useStore();
const { formatMoney } = useMoney();

onBeforeMount(async () => {
  await store.dispatch("updateTypeOfGraph", "QUANTITY");
  await store.dispatch("fetchGraphData");
});

if (!changeShowGraphic) {
  throw new Error("changeShowGraphic not provided");
}

// Configuração das opções do gráfico
const chartOptions = computed(() => {
  const graphData = store.getters["getGraphData"];

  return {
    annotations: {},
    chart: {
      animations: {
        enabled: false,
        easing: "swing",
      },
      background: "#fff",
      fontFamily: "Montserrat",
      height: 470,
      id: "7gTzl",
      stacked: true,
      stackOnlyBar: true,
      toolbar: {
        show: false,
      },
      type: "bar",
      zoom: {
        allowMouseWheelZoom: true,
      },
    },
    plotOptions: {
      line: {
        isSlopeChart: false,
      },
      bar: {
        borderRadius: 6,
        borderRadiusApplication: "around",
        borderRadiusWhenStacked: "all",
        hideZeroBarsWhenGrouped: false,
        isDumbbell: false,
        isFunnel: false,
        isFunnel3d: true,
        dataLabels: {
          total: {
            enabled: false,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#999E9D",
              fontSize: "12px",
              fontWeight: 600,
            },
          },
        },
      },
      bubble: {
        zScaling: true,
      },
      treemap: {
        borderRadius: 4,
        dataLabels: {
          format: "scale",
        },
      },
      radialBar: {
        hollow: {
          background: "#fff",
        },
        dataLabels: {
          name: {},
          value: {},
          total: {},
        },
        barLabels: {
          enabled: false,
          offsetX: 0,
          offsetY: 0,
          useSeriesColors: true,
          fontWeight: 600,
          fontSize: "16px",
        },
      },
      pie: {
        donut: {
          labels: {
            name: {},
            value: {},
            total: {},
          },
        },
      },
    },
    colors: ["#E6AF0C", "#00B07F", "#FF4820", "#3886F9", "#3886F9"],
    dataLabels: {
      enabled: false,
      style: {
        fontWeight: 700,
      },
    },
    fill: {
      opacity: 0.5,
    },
    grid: {
      padding: {
        right: 25,
        left: 15,
      },
    },
    legend: {
      fontSize: 14,
      fontWeight: 400,
      height: "18px",
      labels: {
        colors: ["#999E9D", "#999E9D", "#999E9D", "#999E9D", "#999E9D"],
      },
      offsetY: 0,
      horizontalAlign: "top",
      markers: {
        size: 7,
        shape: "circle",
      },
      itemMargin: {
        vertical: 45,
        horizontal: 16,
      },
    },
    markers: {},
    stroke: {
      width: 6,
      lineCap: "round",
      colors: "transparent",
      dashArray: 1,
      fill: {
        type: "solid",
        opacity: 1,
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: [],
        },
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        var label = w.globals.seriesNames[seriesIndex];
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        var name = "Quantidade";
        let type = store.getters["getTypeOfGraph"];

        if (type === "AMOUNT") {
          data = formatMoney(data, "R$");
          name = "Valor";
        }

        return `
        <div class='tooltip-chart'>
          <div>Status: <strong>${label}</strong></div>
          <div>${name}: <strong>${data}</strong></div>
        </div>
      `;
      },
    },
    xaxis: {
      type: "date",
      categories: graphData ? graphData.dates : [""],
      labels: {
        trim: true,
        style: {},
      },
      group: {
        groups: [],
        style: {
          colors: [],
          fontSize: "12px",
          fontWeight: 400,
          cssClass: "",
        },
      },
      tickPlacement: "between",
      title: {
        style: {
          fontWeight: 700,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      opposite: true,
      labels: {
        style: {
          colors: [],
        },
      },
    },
  };
});

// Dados do gráfico
const series = computed(() => {
  const graphData = store.getters["getGraphData"];

  if (!graphData || !Array.isArray(graphData.data)) {
    return graphData;
  }

  const data = {
    ...graphData,
    data: graphData.data.map((item) => ({
      ...item,
      data: item.data.map((value) => (value === 0 ? null : value)),
      name: translationContractStatus(item.name).text,
    })),
  };

  return data;
});

const activeTab = ref(1);

const total = (value) => {
  let type = store.getters["getTypeOfGraph"];

  if (type === "QUANTITY") {
    return value.total;
  }
  if (type === "AMOUNT") {
    return formatMoney(value.total, "R$");
  }
};

const typeOfTab = async (value) => {
  await store.dispatch("updateTypeOfGraph", value);
  await store.dispatch("fetchGraphData");
};

const setActiveTab = (value) => {
  if (value === 1) {
    typeOfTab("QUANTITY");
  } else if (value === 2) {
    typeOfTab("AMOUNT");
  }
};
</script>

<template>
  <div>
    <div class="metrics-chart">
      <div>
        <p>Quantidade de cobranças</p>
        <h2>{{ total(series) }}</h2>
      </div>

      <div>
        <GrTab @activeTabValue="setActiveTab">
          <GrTabHeaderItem :id="1">Quantidade</GrTabHeaderItem>
          <GrTabHeaderItem :id="2">Valor</GrTabHeaderItem>
        </GrTab>
        <GrTag
          custom-background="#F0F2F5"
          @click="changeShowGraphic"
          class="cursor"
          theme="default"
        >
          <div class="close-chart">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 1L1 13M1 1L13 13"
                stroke="#003B36"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </GrTag>
      </div>
    </div>

    <VueApexCharts
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series.data"
    ></VueApexCharts>
  </div>
</template>

<style lang="scss">
.apexcharts-tooltip {
  background: transparent !important;
  border: none !important;

  .tooltip-chart {
    background-color: var(--dark-500);
    padding: var(--spacing-3);
    border-radius: var(--spacing-2);
    border: none;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: var(--w-full);
      height: var(--h-full);
    }

    > div {
      color: var(--gray-white);

      strong {
        color: inherit;
      }
    }
  }
}
.metrics-chart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-5);

  > div {
    display: flex;
    align-items: center;
    gap: var(--spacing-5);

    p,
    h2 {
      margin: 0 !important;
    }

    p {
      color: var(--gray-200);
      font-size: var(--font-lg);
    }

    h2 {
      color: var(--dark-500);
      font-size: var(--font-3-5xl);
      font-weight: var(--weight-bold);
    }

    &:first-child {
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-2);
    }

    .cursor {
      cursor: pointer;
      border-radius: 50%;
    }

    .close-chart {
      display: block;
      svg {
        width: var(--w-4);
        height: var(--h-3-5);
      }
    }
  }
  @include size(md) {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-5);
  }
}
</style>
