<script setup>
import { markRaw, ref } from "vue";
import i18n from "@/i18n.js";

import { GrDateRangePicker } from "@/components/GrComponents";
import GrFilter from "./contractsListFilter.vue";
import contractExport from "../Modal/contractExport.vue";
import { useStore } from "@/utils/useStore";
import ContractExportEmail from "../Modal/components/ContractExportEmail.vue";

const search = ref({});
const store = useStore()

function setComponentActive() {
  store.dispatch('updateComponentActive', markRaw(ContractExportEmail))
}

</script>

<template>
  <div class="custom-header">
    <div class="texts">
      <h1>{{ i18n.t("views.seller.contracts_new.text_001") }}</h1>
      <p>{{ i18n.t("views.seller.contracts_new.text_002") }}</p>
    </div>
    <div class="content-icons">
      <GrDateRangePicker />
      <GrFilter
        v-model="search"
        :activeFields="[
          'contract_id',
          'product_title',
          'client_name',
          'sale_value',
          'client_email',
          'status',
        ]"
        @input="$root.$emit('updateDataFilter', { ...$event, page: 1 })"
      />
      <div id="TeleporRenderModalContractExport"></div>
      <contractExport>
        <div class="icon" @click="setComponentActive">
          <img src="@/assets/img/icons-contracts/export.svg" alt="export" />
          <p>{{ i18n.t("views.seller.contracts_new.text_041") }}</p>
        </div>
      </contractExport>
    </div>
  </div>
</template>

<style scoped lang="scss">
.custom-header {
  width: var(--w-full);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;

  .texts {
    display: flex;
    flex-direction: column;
    gap: 2px;

    h1 {
      color: var(--dark-500);
      font-size: var(--font-3-5xl);
      font-weight: var(--weight-extrabold);
    }

    p {
      color: var(--gray);
      font-size: var(--font-md);
    }
  }

  .content-icons {
    display: flex;
    align-items: center;
    gap: var(--spacing-8);

    .icon {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      p {
        width: 53px;
        height: 16px;
        left: 1161px;
        top: 205px;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        line-height: 16px;
        color: var(--dark-500);
        font-size: 13px;
        font-weight: 600;
        margin: 0;
        text-align: center;
      }
    }
  }

  @include size(sm) {
    flex-direction: column;
    align-items: flex-end;
    gap: var(--spacing-3);
  }
}
</style>
